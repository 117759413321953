import React from 'react';


const Project = () => {
  
  return (
    <>
      <h1>Project</h1>
    </>
  )
  
}

export default Project