import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap';
import 'antd/dist/reset.css';
import './index.scss'
import App from './App';


ReactDOM.render(
    <App />
    ,
  document.getElementById('root')
);
